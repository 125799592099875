import axios from 'axios';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { handleApiError, showGeneralSuccess } from '../utils';
import { recoverPasswordSchema } from './recover-password.schema';

export const useRecoverPasswordFormik = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: recoverPasswordSchema,
    onSubmit: (values, formikHelpers) => {
      const { setSubmitting } = formikHelpers;
      const { email } = values;

      const data = {
        email
      };

      setSubmitting(true);

      axios
        .post(`${process.env.REACT_APP_BASE_URL_API}/v1/clubs/auth/recoverPassword`, data, {
          headers: {
            'x-transaction-origin': 'cloud-system-login'
          }
        })
        .then(() => {
          setSubmitting(false);
          showGeneralSuccess(
            'Se ha solicitado la recuperación de contraseña. Revise su casilla de email'
          ).then(() => {
            navigate('/');
          });
        })
        .catch((err) => {
          console.log(err);
          handleApiError(err);
          setSubmitting(false);
        });
    }
  });

  return formik;
};
